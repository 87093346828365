<div class="container" *ngIf="auth.user | async as user; else showLogin">
  <div class="topbar">
    <div class="topmain">{{ user.displayName }}</div>
    <div class="topright"><button (click)="logout()">Logout</button></div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<ng-template #showLogin>
  <p>Please login.</p>
  <button (click)="login()">Login with Google</button>
</ng-template>
