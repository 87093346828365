import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { PageService, Portfolio } from './page-service/page.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  activePortfolio: Observable<Portfolio>;

  constructor(
    public auth: AngularFireAuth,
    public pages: PageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if( this.route === this.route.root ) {
      this.auth.user.pipe( filter( user => !!user ), mergeMap( user => this.pages
        .getDefaultPortfolio(user))).subscribe( pf => this.switchPortfolio( pf ));
    }
  }

  switchPortfolio(activePortfolio: Portfolio): void {
    this.router.navigate(['portfolio', activePortfolio.id]);
  }

  login(): void {
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  logout(): void {
    this.auth.signOut();
  }
}
