// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAp67LDPQP2FxlDNDTFXLTHAUMsxycdlcM",
    authDomain: "wiki-639ef.firebaseapp.com",
    databaseURL: "https://wiki-639ef.firebaseio.com",
    projectId: "wiki-639ef",
    storageBucket: "wiki-639ef.appspot.com",
    messagingSenderId: "1022540736485",
    appId: "1:1022540736485:web:c320e4ba3af893de7fb10c",
    measurementId: "G-42H2V06088"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
