import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  ExtraOptions,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { PageComponent } from './page-component/page.component';
import { PortfolioComponent } from './portfolio/portfolio.component';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

const routes: Routes = [
  {
    path: 'portfolio/:portfolioId',
    component: PortfolioComponent,
    children: [
      {
        path: 'page/:pageId',
        component: PageComponent,
      },
      {
        path: 'page/:pageId/edit',
        component: PageComponent,
        canDeactivate: ['noDirtyDeactivate'],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'noDirtyDeactivate',
      useValue: (
        component: PageComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
      ) => !component.dirty,
    },
  ],
})
export class AppRoutingModule {}
