<form [formGroup]="pageForm">
  <div class="page" *ngIf="page">
    <div class="title" *ngIf="editing | async; else titleNotEditing">
      <input type="text" formControlName="name" />
    </div>
    <ng-template #titleNotEditing>
      <div class="title" [routerLink]="['edit']">
        {{ page.name }}
      </div>
    </ng-template>
    <div class="container" *ngIf="editing | async; else contentNotEditing">
      <quill-editor
        [modules]="modules"
        formControlName="content"
      ></quill-editor>
      <div class="bottom">
        <button
          pButton
          type="button"
          label="Save"
          (click)="savePage()"
          [disabled]="!dirty"
        >
          Save
        </button>
      </div>
    </div>
    <ng-template #contentNotEditing>
      <div class="container">
        <quill-view
          [content]="pageForm.controls['content'].value"
          [modules]="modules"
          theme="snow"
        ></quill-view>
      </div>
    </ng-template>
  </div>
</form>
<ng-template #noPage>
  <div class="content">There's nothing to see here.</div>
</ng-template>
