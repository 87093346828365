import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Pipe,
  PipeTransform,
  Renderer2,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Page, PageService } from '../page-service/page.service';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.sanitize(SecurityContext.HTML, value);
  }
}

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements AfterViewChecked, AfterViewInit {
  modules = {
    syntax: true, // Include syntax module
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      source: async (searchTerm, renderList) => {
        const matchedPeople = this.findPageByName(searchTerm).subscribe((res) =>
          renderList(
            res.map((page) => {
              return { id: page.id, value: page.name };
            })
          )
        );
      },
      onSelect: (item, insertItem) => {
        insertItem(item);
        const mentionElement = this.editor.elementRef.nativeElement.querySelector(
          '.mention[data-id="' + item.id + '"]'
        );
        this.renderer.listen(mentionElement, 'click', () =>
          this.refClicked.emit(item.id)
        );
      },
    },
  };

  pageForm = new FormGroup({
    id: new FormControl(),
    name: new FormControl(),
    content: new FormControl(),
  });

  get dirty() {
    return this.pageForm.dirty;
  }

  @ViewChild(QuillEditorComponent, { static: true })
  editor: QuillEditorComponent;

  @Input() portfolioId: string;
  @Input() editing: Observable<boolean>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private pageService: PageService,
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          if (paramMap.has('portfolioId')) {
            this.portfolioId = paramMap.get('portfolioId');
            return pageService.get(
              paramMap.get('portfolioId'),
              paramMap.has('pageId') ? paramMap.get('pageId') : undefined
            );
          } else {
            return of(undefined);
          }
        }),
        tap((page) => (this.page = page))
      )
      .subscribe();

    this.editing = activatedRoute.url.pipe(
      map((frags) => 'edit' == frags[frags.length - 1].path)
    );
  }

  ngAfterViewInit(): void {
    this.pageForm.markAsPristine();
  }

  @Input() set page(newPage: Page) {
    this.pageForm.setValue(Object.assign({ name: '', content: '' }, newPage));
    // if (!newPage.name) {
    //   this.editing = true;
    // }
  }

  get page(): Page {
    return this.pageForm.value as Page;
  }

  findPageByName(searchTerm: string): Observable<Page[]> {
    return this.pageService.findPageByName(this.portfolioId, searchTerm);
  }

  savePage() {
    this.pageService.save(this.portfolioId, this.page).forEach((page) => {
      this.pageForm.markAsPristine();
      this.router.navigate(['portfolio', this.portfolioId, 'page', page.id]);
    });
  }

  @Output() refClicked = new EventEmitter<string>();

  ngAfterViewChecked(): void {
    this.applyRefLinks();
  }

  applyRefLinks() {
    const mentionElement: Node[] = this.elementRef.nativeElement.querySelectorAll(
      '.mention'
    );

    if (mentionElement.length > 0) {
      for (const elem of mentionElement) {
        this.renderer.listen(elem, 'click', ($event) => {
          this.router.navigate(
            ['../', $event.currentTarget.attributes['data-id'].value],
            { relativeTo: this.activatedRoute }
          );
        });
      }
    }
  }
}
