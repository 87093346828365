<div
  class="rightbar"
  *ngIf="portfolio | async as thePortfolio; else sidebarNoPortfolio"
>
  <div class="title">
    {{ thePortfolio.name }}
  </div>
  <div class="filter">
    <input #filter_input (keyup)="filter(filter_input.value)" />
  </div>

  <div class="portfolio">
    <div *ngFor="let page of pages | async">
      <a [routerLink]="['./page', page.id]" routerLinkActive="active">{{
        page.name
      }}</a>
    </div>
  </div>
  <div class="bottom">
    <button (click)="newPage()">New</button>
  </div>
</div>
<ng-template #sidebarNoPortfolio>
  <div class="rightbar"></div>
</ng-template>
<div class="content">
  <router-outlet></router-outlet>
</div>
