import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import { QuillViewComponent } from 'ngx-quill';

import { SafeHtmlPipe } from '../page-component/page.component';

@Component({
  selector: 'app-viewer',
  template: `<quill-view [content]="content" theme="snow"></quill-view>`,

  styleUrls: ['./viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ViewerComponent implements OnInit {
  constructor() {}

  @Input()
  content: string;

  ngOnInit(): void {}
}
