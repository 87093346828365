import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { from, Observable, of } from 'rxjs';
import firebase from 'firebase/app';
import { filter, mergeMap } from 'rxjs/operators';
import { parseHostBindings } from '@angular/compiler';

export interface Portfolio {
  id?: string;
  name: string;
}

export interface Page {
  id?: string;
  name: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {

  static readonly PORTFOLIOS_COLLECTION = 'portfolios';
  static readonly PAGES_COLLECTION = 'pages';

  constructor(private firestore: AngularFirestore) {}

  get(portfolioId: string, pageId?: string): Observable<Page | undefined> {
    return this.firestore.collection<Portfolio>(PageService.PORTFOLIOS_COLLECTION).doc(portfolioId).collection<Page>(PageService.PAGES_COLLECTION).doc(pageId).valueChanges({idField: 'id'});
  }

  getDefaultPortfolio(
    user: firebase.User
  ): Observable<Portfolio> {
    const doc = this.firestore
      .collection<Portfolio>(PageService.PORTFOLIOS_COLLECTION)
      .doc(user.uid);

    doc
      .get()
      .pipe(filter((snap) => !snap.exists))
      .subscribe({
        next: (snap) =>
          doc.set({
            name: user.displayName,
          }),
        error: (err) => console.log('Error: ' + err),
        complete: () => {},
      });

    return doc.valueChanges({idField: 'id'});
  }

  getPorfolio(portfolioId: string): Observable<Portfolio> {
    return this.portfolio(portfolioId).valueChanges({idField: 'id'});
  }


  private portfolio(portfolioId: string) : AngularFirestoreDocument<Portfolio> {
    return this.firestore.collection<Portfolio>(PageService.PORTFOLIOS_COLLECTION).doc(portfolioId);
  }

  getPages(portfolio: Portfolio): Observable<Page[]> {
    return this.firestore.collection<Portfolio>(PageService.PORTFOLIOS_COLLECTION).doc(portfolio.id).collection<Page>(PageService.PAGES_COLLECTION).valueChanges({idField: 'id'});
  }

  save(portfolioId: string,page: Page) : Observable<Page | undefined> {
      const pageDoc = this.portfolio(portfolioId).collection(PageService.PAGES_COLLECTION).doc<Page>(page.id);
      return from(pageDoc.set( page )).pipe(
        mergeMap(()=> pageDoc.valueChanges({idField: 'id'}))
      );
  }

  createId(): string {
    return this.firestore.createId();
  }

  findPageByName(portfolioId: string, searchTerm: string): Observable<Page[]> {
    return this.portfolio(portfolioId).collection<Page>(PageService.PAGES_COLLECTION,
      (ref) => ref.where('name', '>=', searchTerm).where('name','<', searchTerm+'\uffff')).valueChanges();
  }

}
