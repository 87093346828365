import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { combineAll, mergeMap, startWith } from 'rxjs/operators';
import { Page, PageService, Portfolio } from '../page-service/page.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
})
export class PortfolioComponent {
  portfolio: Observable<Portfolio>;
  pages: Observable<Page[]>;

  filter_value = new Subject<string>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private pageService: PageService,
    private router: Router
  ) {
    this.portfolio = activatedRoute.paramMap.pipe(
      mergeMap((paramMap) =>
        pageService.getPorfolio(paramMap.get('portfolioId'))
      )
    );

    this.pages = combineLatest([
      this.portfolio,
      this.filter_value.pipe(startWith('')),
    ]).pipe(
      mergeMap(([portfolio, filter]) =>
        pageService.findPageByName(portfolio.id, filter)
      )
    );

    // this.portfolio.pipe(
    //   mergeMap((portfolio) => pageService.getPages(portfolio))
    // );
  }

  filter(event) {
    this.filter_value.next(event);
  }

  newPage() {
    this.pageService.createId();

    this.router.navigate(['./page', this.pageService.createId(), 'edit'], {
      relativeTo: this.activatedRoute,
    });
  }

  onRefClicked(id: string) {
    this.router.navigate(['./page', id], { relativeTo: this.activatedRoute });
  }
}
